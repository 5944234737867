// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* custom-slick.css */
.slick-prev:before, 
.slick-next:before {
  color: #227c7c;
}

.slick-prev:hover:before, 
.slick-next:hover:before {
  color: #154a4a; 
}`, "",{"version":3,"sources":["webpack://./src/components/Slider/custom-slick.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;;EAEE,cAAc;AAChB;;AAEA;;EAEE,cAAc;AAChB","sourcesContent":["/* custom-slick.css */\n.slick-prev:before, \n.slick-next:before {\n  color: #227c7c;\n}\n\n.slick-prev:hover:before, \n.slick-next:hover:before {\n  color: #154a4a; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
