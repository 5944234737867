// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service_card{
    max-width: 350px !important;
    background-color: #fff;
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
}
.lcon_service{
     width:100%;
    height: 200px;
    margin: auto;
}

.icon_class{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.heading_serv{
    font-size: 36px;
    font-weight: 700;
}
.readmo{
    margin-top: auto;
}
.readmore {
    width: 100%;
    margin-top: auto !important; 
    align-self: center; 
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #007bff; 
    color: #fff; 
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .readmore:hover {
    background-color: #0056b3; 
  }
  .service_card:hover{
    transform: scale(1.1);
    transition: 0.4s;
  }`, "",{"version":3,"sources":["webpack://./src/components/Servicecard/service.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,yCAAyC;IACzC,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;AACA;KACK,UAAU;IACX,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,qBAAqB;IACrB,gBAAgB;EAClB","sourcesContent":[".service_card{\n    max-width: 350px !important;\n    background-color: #fff;\n    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);\n    padding: 10px;\n    border-radius: 5px;\n    margin: 10px;\n    min-height: 500px;\n    display: flex;\n    flex-direction: column;\n}\n.lcon_service{\n     width:100%;\n    height: 200px;\n    margin: auto;\n}\n\n.icon_class{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.heading_serv{\n    font-size: 36px;\n    font-weight: 700;\n}\n.readmo{\n    margin-top: auto;\n}\n.readmore {\n    width: 100%;\n    margin-top: auto !important; \n    align-self: center; \n    padding: 10px 20px;\n    border: none;\n    border-radius: 20px;\n    background-color: #007bff; \n    color: #fff; \n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .readmore:hover {\n    background-color: #0056b3; \n  }\n  .service_card:hover{\n    transform: scale(1.1);\n    transition: 0.4s;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
