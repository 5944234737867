// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Team_cards{
    max-width: 400px;
    background-color: #fff;
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    min-height: fit-content;
}
.team_image{
    width:250px ;
    height: 200px; 

}
.teampic{
    width: 100%;
    height: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/TeamCard/teamcard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,yCAAyC;IACzC,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,aAAa;;AAEjB;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".Team_cards{\n    max-width: 400px;\n    background-color: #fff;\n    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);\n    padding: 10px;\n    border-radius: 5px;\n    margin: 10px;\n    min-height: fit-content;\n}\n.team_image{\n    width:250px ;\n    height: 200px; \n\n}\n.teampic{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
