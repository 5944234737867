// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.careers-info{
    padding:25px 90px 50px 90px;

}

#table-style {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    
    
  }
  
  #table-style td,
  #table-style th {
    border: 1px solid #ddd;
    padding: 8px;
    white-space: nowrap;
    text-align: left;
  }
  
  #table-style tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  #table-style tr:hover {
    background-color: #ddd;
  }
  
  #table-style th {
    width: 51%;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #79ffe2;
    color: #000;
  }
  .font_apply{
    color: #000;
    text-decoration: none;
    font-family: sans-serif;
  }

  .font_apply:hover {
    color: blue;
  }
  @media only screen and (max-width:480px){
    #table-style td,
    #table-style th{
      font-size: 12px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/CareerTable/career.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;;AAE/B;;AAEA;IACI,yCAAyC;IACzC,yBAAyB;IACzB,WAAW;;;EAGb;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,UAAU;IACV,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,yBAAyB;IACzB,WAAW;EACb;EACA;IACE,WAAW;IACX,qBAAqB;IACrB,uBAAuB;EACzB;;EAEA;IACE,WAAW;EACb;EACA;IACE;;MAEE,eAAe;IACjB;EACF","sourcesContent":[".careers-info{\n    padding:25px 90px 50px 90px;\n\n}\n\n#table-style {\n    font-family: Arial, Helvetica, sans-serif;\n    border-collapse: collapse;\n    width: 100%;\n    \n    \n  }\n  \n  #table-style td,\n  #table-style th {\n    border: 1px solid #ddd;\n    padding: 8px;\n    white-space: nowrap;\n    text-align: left;\n  }\n  \n  #table-style tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n  \n  #table-style tr:hover {\n    background-color: #ddd;\n  }\n  \n  #table-style th {\n    width: 51%;\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: left;\n    background-color: #79ffe2;\n    color: #000;\n  }\n  .font_apply{\n    color: #000;\n    text-decoration: none;\n    font-family: sans-serif;\n  }\n\n  .font_apply:hover {\n    color: blue;\n  }\n  @media only screen and (max-width:480px){\n    #table-style td,\n    #table-style th{\n      font-size: 12px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
