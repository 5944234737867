// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client_card{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    width: 70%;
    margin-inline: auto;
    background-color: rgba(121, 255, 226, 0.9);
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    min-height: 160px;
    margin-top: 15px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    gap: 20px;
}
.client_image{
    width: 150px;
    height: 100px;
}
.client_logo{
    width: 100%;
    height: 100%;
    object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/clientCard/client.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,0CAA0C;IAC1C,4CAA4C;IAC5C,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,SAAS;AACb;AACA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".client_card{\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    padding: 20px;\n    width: 70%;\n    margin-inline: auto;\n    background-color: rgba(121, 255, 226, 0.9);\n    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.5);\n    border-radius: 30px;\n    min-height: 160px;\n    margin-top: 15px;\n    margin-bottom: 50px;\n    flex-wrap: wrap;\n    gap: 20px;\n}\n.client_image{\n    width: 150px;\n    height: 100px;\n}\n.client_logo{\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
