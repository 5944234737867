// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service_card {
  max-width: 350px !important;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
}

.service_card:hover {
  background-color: #79ffe2;
  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
}


.lcon_service {
  width: 100%;
  height: 200px;
  margin: auto;
}

.icon_class {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heading_serv {
  font-size: 36px;
  font-weight: 700;
}

.readmo {
  margin-top: auto;
}

.readmore {
  width: 100%;
  margin-top: auto !important;
  align-self: center;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.readmore:hover {
  background-color: #0056b3;
}

.service_heading {
  margin-bottom: 0;
  color: #000;
}

.service_paragraph {
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  padding-inline: 7px;
  color: #000;
}

.icon_class {
  height: 250px;
}`, "",{"version":3,"sources":["webpack://./src/components/Blogcard/blogcard.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,yCAAyC;AAC3C;;;AAGA;EACE,WAAW;EACX,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,2BAA2B;EAC3B,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,oBAAoB;EACpB,4BAA4B;EAC5B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".service_card {\n  max-width: 350px !important;\n  background-color: #fff;\n  padding: 10px;\n  border-radius: 5px;\n  margin: 10px;\n  min-height: 450px;\n  display: flex;\n  flex-direction: column;\n}\n\n.service_card:hover {\n  background-color: #79ffe2;\n  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);\n}\n\n\n.lcon_service {\n  width: 100%;\n  height: 200px;\n  margin: auto;\n}\n\n.icon_class {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.heading_serv {\n  font-size: 36px;\n  font-weight: 700;\n}\n\n.readmo {\n  margin-top: auto;\n}\n\n.readmore {\n  width: 100%;\n  margin-top: auto !important;\n  align-self: center;\n  padding: 10px 20px;\n  border: none;\n  border-radius: 20px;\n  background-color: #000;\n  color: #fff;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.readmore:hover {\n  background-color: #0056b3;\n}\n\n.service_heading {\n  margin-bottom: 0;\n  color: #000;\n}\n\n.service_paragraph {\n  overflow: hidden;\n  text-align: justify;\n  text-overflow: ellipsis;\n  -webkit-line-clamp: 3;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  padding-inline: 7px;\n  color: #000;\n}\n\n.icon_class {\n  height: 250px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
